import React from 'react'

export const ShopifyIcon = () => {
  return (
    <svg width="40" height="46" xmlns="http://www.w3.org/2000/svg">
      <title>Shopify</title>
      <g fill="none" fillRule="evenodd">
        <g id="shopify">
          <path
            d="M34.827 9.067c-.027-.24-.24-.347-.4-.374-.16-.026-3.654-.266-3.654-.266s-2.426-2.4-2.666-2.667c-.267-.267-.774-.187-.987-.133-.027 0-.533.16-1.36.426-.773-2.32-2.213-4.453-4.72-4.453h-.213c-.72-.933-1.6-1.36-2.347-1.36-5.84.027-8.613 7.307-9.493 11.013-2.267.694-3.867 1.2-4.08 1.254-1.254.4-1.307.426-1.467 1.626C3.307 15.067 0 40.667 0 40.667l25.787 4.826L39.76 42.48c0-.027-4.907-33.173-4.933-33.413zm-10.48-2.56c-.64.213-1.387.426-2.187.666v-.48c0-1.44-.213-2.613-.533-3.52 1.306.187 2.186 1.654 2.72 3.334zm-4.294-3.04c.347.906.587 2.186.587 3.92v.24c-1.413.426-2.96.906-4.507 1.386.88-3.306 2.507-4.933 3.92-5.546zM18.32 1.84c.24 0 .507.08.747.24-1.867.88-3.867 3.093-4.72 7.52-1.227.373-2.454.747-3.574 1.093 1.014-3.36 3.36-8.853 7.547-8.853z"
            fill="currentColor"
            fillRule="nonzero"
          />
          <path
            d="M34.427 8.693c-.16-.026-3.654-.266-3.654-.266s-2.426-2.4-2.666-2.667a.526.526 0 0 0-.374-.16l-1.946 39.893L39.76 42.48S34.853 9.307 34.827 9.093c-.027-.24-.24-.373-.4-.4z"
            id="Path"
            fill="#7E7E7E"
          />
          <path
            d="M21.04 16.427l-1.733 5.12s-1.52-.8-3.36-.8c-2.72 0-2.854 1.706-2.854 2.133 0 2.347 6.107 3.227 6.107 8.72 0 4.32-2.747 7.093-6.427 7.093-4.426 0-6.693-2.746-6.693-2.746l1.173-3.92s2.32 2 4.294 2c1.28 0 1.813-1.014 1.813-1.76 0-3.067-5.013-3.2-5.013-8.214 0-4.213 3.04-8.32 9.146-8.32 2.4.027 3.547.694 3.547.694z"
            id="Path"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}
